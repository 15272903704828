import { Axios } from 'utils/Axios';

export const getListMemoryLog = async () => {
  // ${encodeURIComponent(filter.search)}
  try {
    const URL = `/memory-log?sort=created_at-`;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const editMemoryLog = async (payload, idLog) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `/memory-log/${idLog}`;
    const response = await Axios.put(URL, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const deleteMemoryLog = async (idLog) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `/memory-log/${idLog}`;
    const response = await Axios.delete(URL);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAllMemoryLog = async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const URL = `/memory-log`;
    const response = await Axios.delete(URL);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
