import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navbar } from './sidebar.elements';
import { useLocation, useNavigate } from 'react-router-dom';
import Image from 'components/UI/atoms/Image';
import LogoConfluidity from 'components/assets/images/Logo_Confluidty.png';
import IconConfluidity from 'components/assets/images/Icon_Confluidity.png';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowUpIcon,
  ChatbotActiveIcon,
  ChatbotIcon,
  FolderMenuActiveIcon,
  FolderMenuIcon,
  LogActiveIcon,
  LogIcon,
  ManageOrgActiveIcon,
  ManageOrgIcon,
  PlusCircleIcon,
  OrganizationInfoActiveIcon,
  OrganizationInfoIcon,
  UsersMenuActiveIcon,
  UsersMenuIcon,
  ApiMenuIcon,
  ApiMenuIconActive,
  FolderIcon,
  KeyIcon,
  PadLockIcon,
  LogOutIcon,
  SearchIcon,
  CloseIcon,
  TagIcon,
  KnowledgeMenuIcon,
  KnowledgeMenuActiveIcon,
} from 'components/UI/atoms/Icons';
import { checkRoleAuth, clearLocalStorageData } from 'utils/authUtils';
import CardItemHistoryChat from 'components/UI/molecules/CardItemHistoryChat';
import { MessageContext } from 'contexts/GlobalStateMessage';
import { useDetectOutsideClick } from 'helper/useDetectOutsideClick';
import {
  GlobalStateContext,
  GlobalStateUpdaterContext,
} from 'contexts/GlobalStateContext';
import NavbarMobile from '../Navbar/NavbarMobile';
import DefaultUser from 'components/assets/images/default-picture.png';
import { getGlobalSearch } from 'services/chat/globalSearch';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

const role = checkRoleAuth();
const menuItems = [
  // {
  //   label: 'Chatbot',
  //   path: '/chatbot',
  //   icon: { default: <ChatbotIcon />, active: <ChatbotActiveIcon /> },
  //   children: [
  //     { label: 'Upload Document Rahasia', path: '/chatbot/submenu1' },
  //     { label: 'Edit Document Tag', path: '/chatbot/submenu2' },
  //   ],
  // },
  // {
  //   label: 'Company Structure',
  //   path: '/company',
  //   icon: { default: <GitMergeIcon />, active: <GitMergeActiveIcon /> },
  // },
  // {
  //   label: 'Document',
  //   path: '/document',
  //   icon: { default: <DocumentIcon />, active: <DocumentActiveIcon /> },
  // },
  ...(role === 'SUPER_ADMIN'
    ? [
        {
          label: 'Organization Management',
          path: '/organization-management',
          icon: { default: <ManageOrgIcon />, active: <ManageOrgActiveIcon /> },
        },
        {
          label: 'History',
          path: '/history-log',
          icon: { default: <LogIcon />, active: <LogActiveIcon /> },
        },
      ]
    : []),
  ...(role === 'ADMIN'
    ? [
        {
          label: 'Organization Info',
          path: '/organization-info',
          icon: {
            default: <OrganizationInfoIcon />,
            active: <OrganizationInfoActiveIcon />,
          },
        },
        {
          label: 'Knowledge',
          path: '/knowledge',
          icon: {
            default: <KnowledgeMenuIcon />,
            active: <KnowledgeMenuActiveIcon />,
          },
        },
        {
          label: 'Users',
          path: '/users',
          icon: {
            default: <UsersMenuIcon />,
            active: <UsersMenuActiveIcon />,
          },
        },
        {
          label: 'Document',
          path: '/document-admin',
          icon: {
            default: <FolderMenuIcon />,
            active: <FolderMenuActiveIcon />,
          },
        },
        {
          label: 'History',
          path: '/history-log',
          icon: { default: <LogIcon />, active: <LogActiveIcon /> },
        },
        {
          label: 'Integrated Database',
          path: '/api-list',
          icon: { default: <ApiMenuIcon />, active: <ApiMenuIconActive /> },
        },
      ]
    : []),
  ...(role === 'USER'
    ? [
        {
          label: 'Document',
          path: '/document',
          icon: {
            default: <FolderMenuIcon />,
            active: <FolderMenuActiveIcon />,
          },
        },
        {
          label: 'Tag',
          path: '/tag',
          icon: {
            default: <TagIcon width="22" height="22" />,
            active: <TagIcon width="22" height="22" color="#6D51D7" />,
          },
        },
      ]
    : []),
];

const dummySearchList = {
  chat_id: 653,
  name: 'Deskripsikan dokumen berikut',
  message_id: '653_20241031143339_0',
  message: 'Deskripsikan dokumen berikut ini disini lihat',
  highlight: 'Deskripsikan <b>dokumen</b> berikut ini disini lihat',
};

const initialParamsGlobalSearch = {
  search: '',
  page: 1,
  limit: 2,
  hasMore: true,
  fetchMore: false,
};

const Sidebar = () => {
  const nameUser = localStorage.getItem('name');

  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState({});
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDelayedContent, setShowDelayedContent] = useState(false);

  const responsiveSide = useContext(GlobalStateContext);
  const setResponsiveSide = useContext(GlobalStateUpdaterContext);
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, true);

  const [globalSearchList, setGlobalSearchList] = useState([dummySearchList]);
  const [MetaGlobalSearch, setMetaGlobalSearch] = useState({
    total: 0,
    last_page: 1,
    page: 1,
  });
  const [ParamsGlobalSearch, setParamsGlobalSearch] = useState(
    initialParamsGlobalSearch
  );

  const onClickMenu = () => {
    setIsActive(!isActive);
  };
  const { setMessage, setFormData } = useContext(MessageContext);

  useEffect(() => {
    // clear path on refresh and clear global search result

    let path = window.location;
    let separatePath = path?.pathname?.split(`/`);

    if (
      path?.hash &&
      separatePath.length === 4 &&
      ParamsGlobalSearch?.search === ''
    ) {
      separatePath.pop(); // remove last path
      const originalPath = separatePath.join('/'); // pathname whitout globalSearch params
      navigate(originalPath);
    }
  }, []);

  useEffect(() => {
    let timer;
    if (!responsiveSide) {
      timer = setTimeout(() => {
        setShowDelayedContent(true);
      }, 300); // Set your delay here in milliseconds
    } else {
      setShowDelayedContent(false);
    }
    return () => clearTimeout(timer);
  }, [responsiveSide]);

  const toggleExpand = (label) => {
    setExpanded((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const togleResponsiveSide = () => {
    setResponsiveSide(!responsiveSide);
  };

  const handleGlobalSearch = (e) => {
    let val = e.target.value;
    setParamsGlobalSearch((prev) => ({
      ...prev,
      search: val,
      fetchMore: false,
    }));
  };

  useEffect(() => {
    console.log('GlobalSearchList', globalSearchList);

    if (ParamsGlobalSearch?.search?.split('')?.length > 2) {
      SearchGlobalKeyword();
    }
    if (ParamsGlobalSearch?.search === '') setGlobalSearchList([]);
  }, [ParamsGlobalSearch]);

  const SearchGlobalKeyword = async () => {
    try {
      const res = await getGlobalSearch({
        search: ParamsGlobalSearch.search,
        page: ParamsGlobalSearch.page,
        limit: ParamsGlobalSearch.limit,
      });

      if (res?.meta?.http_status === 200) {
        setMetaGlobalSearch(res?.meta);
        const dataMessages = res?.data?.messages ?? [];
        console.log('ParamsGlobalSearch', ParamsGlobalSearch);
        console.log('dataMessages', dataMessages);

        if (ParamsGlobalSearch?.fetchMore) {
          setGlobalSearchList((prev) => prev.concat(dataMessages));

          if (MetaGlobalSearch.page >= MetaGlobalSearch.last_page)
            setParamsGlobalSearch({
              ...ParamsGlobalSearch,
              fetchMore: false,
            });
        } else if (ParamsGlobalSearch?.hasMore)
          setGlobalSearchList(dataMessages);
      }
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0].message ?? error?.message);
    }
  };

  const fetchMoreDataLayanan = () => {
    console.log(
      'fetchMoreDataLayanan',
      MetaGlobalSearch.total,
      globalSearchList.length
    );

    if (MetaGlobalSearch.page >= MetaGlobalSearch.last_page) {
      console.log('stop', MetaGlobalSearch.total, globalSearchList.length);

      setParamsGlobalSearch({
        ...ParamsGlobalSearch,
        hasMore: false,
      });
      return;
    }
    setTimeout(() => {
      setParamsGlobalSearch((prevPage) => ({
        ...prevPage,
        page: prevPage.page + 1,
        fetchMore: true,
      }));
    }, 500);
  };

  const handleNewChat = () => {
    setFormData({
      chat_id: '',
      files: [],
      message: '',
    });
    setMessage([]);
  };

  const handleNewChatDetail = () => {
    setFormData({
      chat_id: '',
      files: [],
      message: '',
    });
    setMessage([]);
    navigate('/chatbot');
  };

  const handleLogout = () => {
    clearLocalStorageData();
    window.location.href = '/login';
  };

  const handleChangePassword = () => {
    window.location.href = '/change-password';
  };

  return (
    <React.Fragment>
      <NavbarMobile onClick={onClickMenu} ref={dropdownRef} />
      <Navbar
        className={`${isActive ? 'active' : 'inactive'} fixed top-0 left-0 z-50 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 aria-label="Sidebar" ${responsiveSide && 'small-nav'}`}
        id="default-sidebar"
      >
        <div className="w-full mt-4 py-5 flex items-center relative">
          <div
            className={`flex flex-grow ${responsiveSide ? 'justify-center' : 'justify-between pl-5'} w-10 align-middle`}
          >
            <Image
              src={responsiveSide ? IconConfluidity : LogoConfluidity}
              alt="logo confluidity"
              className="cursor-pointer"
            />
            {!isActive && !responsiveSide && (
              <span className="text-xs text-slate-500 mr-auto ml-2">
                {role === 'SUPER_ADMIN' && 'Admin Panel'}
              </span>
            )}
          </div>
          <div
            onClick={togleResponsiveSide}
            className="w-7 h-7 bg-white items-center justify-center border-2 rounded-full absolute right-0 transform translate-x-1/2 cursor-pointer md:block hidden"
          >
            {responsiveSide ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          </div>
        </div>
        {/* di figma super admin gada fitur chat? */}
        {role !== 'SUPER_ADMIN' && (
          <div className="w-full transition-all duration-500 flex justify-center py-3 px-5">
            {responsiveSide ? (
              <div
                className="cursor-pointer"
                onClick={() => navigate('/chatbot')}
              >
                {location.pathname.startsWith('/chatbot') ? (
                  <ChatbotActiveIcon />
                ) : (
                  <ChatbotIcon />
                )}
              </div>
            ) : (
              <button
                onClick={
                  location.pathname === '/chatbot'
                    ? handleNewChat
                    : handleNewChatDetail
                }
                className={`flex w-full justify-center items-center gap-2 rounded-lg my-2 px-3 py-2 text-base font-bold leading-6 text-purple-600 shadow-sm hover:bg-purple-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600 bg-purple-100 transition-all duration-500`}
              >
                <PlusCircleIcon color="#5D42BD" width="24" height="24" /> New
                Chat
              </button>
            )}
          </div>
        )}
        <div className="relative m-3 mt-0">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            <SearchIcon />
          </div>
          <input
            id="global_search"
            name="global_search"
            type={'text'}
            placeholder="Search"
            value={ParamsGlobalSearch.search}
            onChange={handleGlobalSearch}
            className="block w-full rounded-md py-2 px-7 text-gray-900 shadow-sm ring-0 outline-none border-2 sm:text-sm sm:leading-6 pl-8"
          />
          {globalSearchList.length > 0 && (
            <div
              className="absolute inset-y-0 right-2 flex items-center cursor-pointer"
              onClick={() =>
                setParamsGlobalSearch((prev) => ({ ...prev, search: '' }))
              }
            >
              <CloseIcon width="14" height="14" />
            </div>
          )}
        </div>
        {ParamsGlobalSearch?.search?.split('').length > 2 ? (
          <div
            className={`w-full border-[1px] border-[lightgray] mt-1 rounded-md z-20 h-fit overflow-hidden !h-full drop-shadow-xl overflow-y-auto`}
            id="scrollableDiv"
            // ref={onContainerRefChange}
          >
            {globalSearchList.length > 0 ? (
              <InfiniteScroll
                className="w-full flex flex-col gap-1 overflow-y-scroll h-full"
                dataLength={globalSearchList.length}
                next={fetchMoreDataLayanan}
                hasMore={ParamsGlobalSearch.page < MetaGlobalSearch.last_page}
                loader={
                  <h4 className="w-full text-center">
                    <Spin />
                  </h4>
                }
                // height={120}
                scrollableTarget={'scrollableDiv'}
                useWindow={false}
              >
                {globalSearchList.map((message, i) => (
                  <div
                    key={i}
                    className="py-2 global-search-item cursor-pointer pl-4 pr-1"
                    onClick={() =>
                      navigate(
                        `/chatbot/${message.chat_id}/${ParamsGlobalSearch.search}#${message.message_id}`
                      )
                    }
                  >
                    <p className="overflow-hidden text-ellipsis line-clamp-1 pb-1">
                      {message.message}
                    </p>
                    <div
                      className="text-gray-500 text-sm overflow-hidden text-ellipsis line-clamp-1"
                      dangerouslySetInnerHTML={{ __html: message.highlight }}
                    />
                  </div>
                ))}
              </InfiniteScroll>
            ) : (
              <div className="py-2 global-search-item text-center pl-4 pr-1">
                <p className="overflow-hidden text-ellipsis line-clamp-1 py-4 text-gray-600">
                  {"Keyword's not found"}
                </p>
              </div>
            )}
          </div>
        ) : (
          <>
            {menuItems?.map((item) => (
              <div
                key={item.path}
                className="w-full transition-all duration-500"
              >
                {responsiveSide && !showDelayedContent && (
                  <div
                    onClick={() => navigate(item.path)}
                    className={`w-full justify-center inline-flex items-center gap-2 cursor-pointer ${
                      location.pathname.startsWith(item.path)
                        ? 'text-blue-500'
                        : 'text-slate-500'
                    }`}
                  >
                    {location.pathname.startsWith(item.path)
                      ? item.icon.active
                      : item.icon.default}
                  </div>
                )}

                {!responsiveSide && showDelayedContent && (
                  <div
                    onClick={() => navigate(item.path)}
                    className={`w-full ${responsiveSide ? 'justify-center' : 'px-3'} inline-flex items-center gap-2 cursor-pointer ${
                      location.pathname.startsWith(item.path)
                        ? 'text-blue-500'
                        : 'text-slate-500'
                    }`}
                    style={{
                      color: location.pathname.startsWith(item.path)
                        ? '#6D51D7'
                        : '#64748b',
                    }}
                  >
                    <div
                      className={`w-full flex flex-wrap  px-3 py-3 rounded-lg items-center gap-2 ${
                        location.pathname.startsWith(item.path) &&
                        'bg-slate-100'
                      }`}
                    >
                      {location.pathname.startsWith(item.path)
                        ? item.icon.active
                        : item.icon.default}

                      {!responsiveSide && showDelayedContent && (
                        <div className="flex items-center">
                          <span
                            className={`font-medium ${role === 'SUPER_ADMIN' ? 'text-xs' : 'text-base'}`}
                          >
                            {item.label}
                          </span>
                          {item.label === 'Chatbot' && (
                            <div
                              className="ml-16"
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleExpand(item.label);
                              }}
                            >
                              {expanded[item.label] ? (
                                <ArrowUpIcon />
                              ) : (
                                <ArrowDownIcon />
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
            {role === 'ADMIN' && (
              <div className="px-4 mt-2">
                <div className="border-t-2"></div>
              </div>
            )}
            {!responsiveSide && role !== 'SUPER_ADMIN' && (
              <CardItemHistoryChat />
            )}
          </>
        )}

        <div className="px-4 mt-auto md:hidden">
          <div className="border-t border-gray-100 px-3">
            <div
              className="flex items-center gap-2 py-3"
              onClick={() => setShowDropDown(!showDropDown)}
            >
              <div className="bg-slate-500 h-8 w-8 rounded-full flex items-center justify-center">
                <Image
                  src={DefaultUser}
                  alt="logo confluidity"
                  className="w-5 h-5"
                />
              </div>
              <div className="text-sm font-semibold">{nameUser}</div>
              <div className="ml-auto">
                <ArrowDownIcon />
              </div>
            </div>
            <div className={`relative ${showDropDown ? '' : 'h-0 hidden'} `}>
              <div className="flex gap-3 py-3">
                <FolderIcon width="18" height="18" />{' '}
                <span className="font-medium text-sm">Memory Log</span>
              </div>
              <div className="flex gap-3 py-3">
                <KeyIcon />{' '}
                <span className="font-medium text-sm">Setup API Keys</span>
              </div>
              <div className="flex gap-3 py-3" onClick={handleChangePassword}>
                <PadLockIcon width="22" height="22" />{' '}
                <span className="font-medium text-sm">Edit Password</span>
              </div>
            </div>
            <div className="flex gap-3 py-3" onClick={handleLogout}>
              <LogOutIcon /> <span className="font-medium text-sm">Logout</span>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default Sidebar;
