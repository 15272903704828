import React from 'react';

const Button = ({
  type = 'button',
  onClick,
  disabled = false,
  icon = '',
  text = '',
  outline = false,
  textColor,
  className = '',
}) => {
  return icon ? (
    outline ? (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`flex w-auto justify-center items-center gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-6 ${textColor ? textColor : 'text-black'} shadow-sm hover:bg-black-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 border-slate-300 border-2 ${className}`}
      >
        {icon} {text}
      </button>
    ) : (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`flex w-auto justify-center items-center gap-2 rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold leading-6 ${textColor ? textColor : 'text-white'} shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 ${className}`}
      >
        {icon} {text}
      </button>
    )
  ) : outline ? (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex w-auto justify-center items-center gap-2 rounded-lg px-3 py-2 text-base font-semibold leading-6 ${textColor ? textColor : 'text-black'} shadow-sm hover:bg-black-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black-600 border-slate-300 border-2`}
    >
      {text}
    </button>
  ) : (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`flex w-auto justify-center items-center gap-2 rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold leading-6 ${textColor ? textColor : 'text-white'} shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
    >
      {text}
    </button>
  );
};

export default Button;
