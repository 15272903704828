import { Axios } from 'utils/Axios';

export const getGlobalSearch = async ({ search, page, limit }) => {
  try {
    const URL = `/chats/messages?search=${search}&page=${page}&limit=${limit}`;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};
